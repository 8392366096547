import React from 'react';
import { CssBaseline,TextField,Grid,Typography,Container,Button,Snackbar,Avatar,CircularProgress  } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import api from '../services/api';
import Menu from './Menu';
import { logout } from '../services/auth';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUp = (props) => {
  const classes = useStyles();
  const [dataForm, setDataForm] = React.useState('');
  const [dataAlert, setDataAlert] = React.useState('');
  const [loading, setLoading] = React.useState(false); 
  const [open, toggleOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [empresas, setEmpresas] = React.useState('');
  const handleClose = () => {
    setDialogEmpresa({
      IdEmpresa: '',
      Nome: '',
    });

    toggleOpen(false);
  };

  const [dialogEmpresa, setDialogEmpresa] = React.useState({
    IdEmpresa: '',
    Nome: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      IdEmpresa: dialogEmpresa.id,
      Nome: dialogEmpresa.Nome,
    });

    handleClose();
  };

  let loadingRef = React.createRef();
  const handleSubmited = async (event) => {
    event.preventDefault();
    setLoading(true);
    var formData = new FormData();
    formData.append("dataNome", dataForm.Nome);
    formData.append("dataNomeEmpresa",value.Nome);
    formData.append("dataIdEmpresa",value.IdEmpresa);
    formData.append("dataEmail",dataForm.Email);
    formData.append("dataSenha",dataForm.Senha);
    const response = await api.post("?method=tryRegister",formData)
    const data = response.data;
    var dados = {
        message: null,
        status: true
    };
    if(data.status){
      dados.message = "Usuário cadastrado com sucesso!";
      setTimeout(()=> {document.location.reload()},5000);
    } else {
      dados.message = data.error;
    }
    setDataAlert(dados);
    setLoading(false);
  }
  React.useEffect(() => {
    if(dataAlert.status){
        setTimeout(()=> {setDataAlert({message: null,status: false})}, 5000)
    }
    async function teste(){
      if(!empresas){
        const response = await api.get("?method=getAllEmpresas")
        if(response.data.status){
          setEmpresas(response.data.data);
        } else {
          logout();
          props.history.push("/login");
        }
      }
    }
    teste();
    const myReference = loadingRef.current;
    myReference.style.display = loading ? "block" : "none";
  });
  return (
    <div style={{overflowY: "hidden"}}>
      <Menu />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Cadastro de usuários
          </Typography>
          <form className={classes.form}>
            <Autocomplete
              value={value}
              fullWidth
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    toggleOpen(true);
                    setDialogEmpresa({
                      IdEmpresa: '',
                      Nome: newValue,
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  toggleOpen(true);
                  setDialogEmpresa({
                    Nome: newValue.inputValue,
                    IdEmpresa: '',
                  });
                } else {
                  setValue(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    Nome: `Adicionar empresa "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              id="free-solo-dialog-demo"
              options={empresas ? empresas : []}
              getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.Nome;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderOption={(option) => option.Nome}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Nome empresa" variant="outlined" />
              )}
            />
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">Adicionar nova empresa</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Essa empresa não existe, deseja adiciona-lá e assim vincular com o usuário?
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={dialogEmpresa.Nome}
                    onChange={(event) => setDialogEmpresa({ ...dialogEmpresa, Nome: event.target.value })}
                    label="Nome"
                    type="text"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancelar
                  </Button>
                  <Button type="submit" color="primary">
                    Adicionar
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="nome"
              label="Nome do usuário"
              name="nomeuser"
              autoComplete="name"
              onChange={e => setDataForm({...dataForm, Nome: e.target.value})}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              onChange={e => setDataForm({...dataForm, Email: e.target.value})}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => setDataForm({...dataForm, Senha: e.target.value})}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmited}
            >
              <Grid container justify="center" spacing={1}>
                  <Grid key={0} item>
                      <CircularProgress ref={loadingRef} size={20} style={{marginTop: 2,color: "#66b4ce",display: "none"}} open={loading} />
                  </Grid>
                  <Grid key={1} item>
                      Cadastrar
                  </Grid>
              </Grid>
            </Button>
          </form>
        </div>
        <Snackbar
          open={dataAlert.status}
          anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
          message={dataAlert.message}
          key={"bottom+center"}
        />
      </Container>
    </div>
  );
}
export default withRouter(SignUp);
export const TOKEN_KEY = "@ideias-token";
export const isAuthenticated = () => (localStorage.getItem(TOKEN_KEY) !== null) && (localStorage.getItem('userState') !== null);
export const isAdmin = () => localStorage.getItem('userState') !== null ? JSON.parse(localStorage.getItem('userState')).isAdmin === "1" : false;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.setItem('userState', null);
  localStorage.removeItem(TOKEN_KEY);
};
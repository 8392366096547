import React from 'react';
import { AppBar,Toolbar,Typography,Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/imgs/FluxOpus2.png';
import '../App.css';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import MenuListMake from './MenuListMake';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        justifyContent: "center",
        display: "flex",
        cursor: "pointer"
    },
    colorPrimary: {
        backgroundColor: "#66b4ce",
    }
}));

const Menu = (props) => {
    const classes = useStyles();
    const [userState] = React.useState(JSON.parse(localStorage.getItem('userState')) || '');
    
    return (
        <AppBar position="static" className={classes.colorPrimary}>
            <Toolbar>
            <Typography variant="h6" className={classes.title} onClick={() => props.history.push('/')}>
                <img src={logo} className="App-logo" alt="logo" />
            </Typography>
            {userState ? 
            <MenuListMake history={props.history} nome={userState["Nome"]} isAdmin={userState["isAdmin"]} />
            : 
            <Link to="/login" style={{textDecoration: "none",color: "white"}}>
                <Button color="inherit">Entrar</Button>
            </Link>}
            </Toolbar>
        </AppBar>
    );
}
export default withRouter(Menu);

import React from 'react';
import './App.css';
import Inicial from './components/Inicial';

import {withRouter} from 'react-router-dom';

function App() {
  

  return (
    <div className="App" style={{overflow: "hidden"}}>
      <Inicial />
    </div>
  );
}

export default withRouter(App);

import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "http://192.168.0.112:8090/projeto_ideias/api.php" : "http://ideias.opus127.com.br/api"
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
import React, { Component } from 'react';
import logo from '../assets/imgs/notfound.jpg';
import './NotFound.css';
import { withRouter } from 'react-router-dom';
import Menu from './Menu';

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
        <div style={{overflowY: "hidden"}}>
          <Menu />
          <header className="NotFound-header">
            <img src={logo} className="logo-notfound" alt="logo" />
          </header>
        </div>
    );
  }
};

export default withRouter(NotFound);
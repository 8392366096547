import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import MaterialTable , {MTableToolbar} from 'material-table';
import { CssBaseline,Container,Paper,IconButton } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import IdeiaComponent from "./IdeiaComponent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import api from '../services/api';
import ModalRegistrarIdeia from "./ModalRegistrarIdeia";
import { logout } from '../services/auth';
import Menu from './Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = theme => ({
    paper: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    }, 
});
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Ideias extends Component {
    tableRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            filter: {
                status: "Nova"
            },
            loggedUserData: JSON.parse(localStorage.getItem("userState")) || [],
            tabs: 0,
            messageInformation: "Aqui são as ideias novas e recente adicionadas e esperando a analise da equipe Opus127."
        };
    }
    componentDidMount() {
        if(this.props.location.search === "?implementadas") {
            this.setState({tabs: this.state.loggedUserData.isAdmin === "1" ? 4 : 3,filter:{status:"Implementada"},messageInformation: "Aqui são as ideias implementadas no qual foi analisada e desenvolvida pela equipe de desenvolvimento e já está disponível no Fluxus."},() => this.tableRef.current.onQueryChange())
        }
        else if(this.props.location.search === "?Analisadas") {
            this.setState({tabs: this.state.loggedUserData.isAdmin === "1" ? 3 : 2,filter:{status:"Em análise"},messageInformation: "Aqui são as ideias em análise aquelas que receberam uma boa quantidade de votos e agora está em análise da equipe de desenvolvimento."},() => this.tableRef.current.onQueryChange())
        }
        else if(this.state.loggedUserData.isAdmin === "1"){
            this.setState({tabs: 1});
        }
    }
    componentDidUpdate() {
        console.log(this.tableRef.current);
    }
    render() {
        const { classes } = this.props;

        return (
            <div style={{overflowY: "hidden"}}>
                <Menu />
                <Container component="main" maxWidth="lg">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <div style={{backgroundColor: "white"}}>
                            <IconButton edge="start" style={{backgroundColor: "#f1f1f1",marginLeft: 5,marginTop: 5,marginBottom: 5}} color="inherit" aria-label="menu" onClick={this.props.history.goBack}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <Paper square>
                            <Tabs
                                style={{width:"100%"}}
                                value={this.state.tabs}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(event, tabs) => this.setState({tabs})}
                                aria-label="filtro de ideias"
                                >
                                {this.state.loggedUserData.isAdmin === "1" && 
                                <Tab style={{minWidth:"20%"}} label="Descartadas" onClick={
                                    (e) => 
                                        {
                                            this.setState({filter:{status:"Descartada"},messageInformation: "Aqui são as ideias descartadas, no qual foi analisada pela equipe da Opus 127."},() => this.tableRef.current.onQueryChange());
                                            this.tableRef.current.setState({...this.tableRef.current.state, currentPage: 0, query: {...this.tableRef.current.state.query, page: 0}}); 
                                        }
                                    } 
                                />
                                }
                                <Tab style={{minWidth: this.state.loggedUserData.isAdmin === "1" ? "20%" : "25%"}} label="Novas" onClick={(e) => {
                                    this.setState({filter:{status:"Nova"},messageInformation: "Aqui são as ideias novas e as ideias recente adicionadas e esperando a analise da equipe Opus127."},() => this.tableRef.current.onQueryChange())
                                    this.tableRef.current.setState({...this.tableRef.current.state, currentPage: 0, query: {...this.tableRef.current.state.query, page: 0}}); 
                                }} />
                                <Tab style={{minWidth: this.state.loggedUserData.isAdmin === "1" ? "20%" : "25%"}} label="Qualificadas" onClick={(e) => {
                                    this.setState({filter:{status:"Qualificada"},messageInformation: "Aqui são as ideias qualificadas, aquelas que foram aprovadas pela equipe Opus127 e estão esperando o voto público para ser descutida e assim implementada."},() => this.tableRef.current.onQueryChange())
                                    this.tableRef.current.setState({...this.tableRef.current.state, currentPage: 0, query: {...this.tableRef.current.state.query, page: 0}});     
                                }}/>
                                <Tab style={{minWidth: this.state.loggedUserData.isAdmin === "1" ? "20%" : "25%"}} label="Em análise" onClick={(e) => {
                                    this.setState({filter:{status:"Em análise"},messageInformation: "Aqui são as ideias em análise, aquelas que receberam uma boa quantidade de votos e agora está em análise da equipe de desenvolvimento."},() => this.tableRef.current.onQueryChange())
                                    this.tableRef.current.setState({...this.tableRef.current.state, currentPage: 0, query: {...this.tableRef.current.state.query, page: 0}});     
                                }}/>
                                <Tab style={{minWidth: this.state.loggedUserData.isAdmin === "1" ? "20%" : "25%"}} label="Implementadas" onClick={(e) => {
                                    this.setState({filter:{status:"Implementada"},messageInformation: "Aqui são as ideias implementadas, no qual foi analisada e desenvolvida pela equipe de desenvolvimento e já está disponível no Fluxus."},() => this.tableRef.current.onQueryChange())
                                    this.tableRef.current.setState({...this.tableRef.current.state, currentPage: 0, query: {...this.tableRef.current.state.query, page: 0}}); 
                                }}/>
                            </Tabs>
                            <Paper square style={{
                                padding: "30px",
                                width: "100%"
                            }}>
                                <Alert style={{
                                    width: "100%",
                                }} severity="warning">{this.state.messageInformation}</Alert>
                            </Paper>
                        </Paper>
                        <MaterialTable
                            tableRef={this.tableRef}
                            title="Ideias"
                            components={{
                                Toolbar: props => (
                                    <div>
                                        <div style={{padding:10,float:"right"}}>
                                            <ModalRegistrarIdeia />
                                        </div>
                                        <MTableToolbar {...props} />
                                    </div>
                                ),
                            }}
                            columns={[
                                { title: "", field: "row", render: rowData => <IdeiaComponent {...rowData} isAdmin={this.state.loggedUserData.isAdmin === "1"} />,headerStyle: {display: "none"},
                                customFilterAndSearch: (term, rowData) => console.log(term) },
                            ]}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    // prepare your data and then call resolve like this:
                                    let url = "?method=getAllIdeas&"
                                    url += 'per_page=' + query.pageSize
                                    url += '&page=' + (query.page + 1)
                                    url += '&status='+ this.state.filter.status
                                    api.get(url)
                                        .then(response => response.data)
                                        .then(result => {
                                            if(!result.status){
                                                logout();
                                                this.props.history.push("/login");
                                                return;
                                            }
                                            resolve({
                                                data: result.data.filter(data => data.Descricao.includes(query.search)),
                                                page: parseInt(result.page) - 1,
                                                totalCount: parseInt(result.totalCount)
                                            })
                                        })
                                })
                            }
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'Nenhum registro para exibir'
                                },
                                toolbar: {
                                    searchTooltip: 'Pesquisar',
                                    searchPlaceholder: "Procure pela descrição"
                                },
                                pagination: {
                                    labelRowsSelect: 'linhas',
                                    labelDisplayedRows: '{count} de {from}-{to}',
                                    firstTooltip: 'Primeira página',
                                    previousTooltip: 'Página anterior',
                                    nextTooltip: 'Próxima página',
                                    lastTooltip: 'Última página'
                                }
                            }}
                            onRowClick={(event, rowData) => this.props.history.push("/ideia/"+rowData.IdIdeia)}
                        />
                    </div>
                </Container>
            </div>
        );
    }
}
export default withRouter(withStyles(useStyles)(Ideias));

import React, { Component } from 'react';
import { Backdrop,Collapse,IconButton,InputLabel,Select,TextField,CssBaseline,Grid,Typography,Container,Button,Snackbar,Avatar,CircularProgress,Paper  } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Alert,Skeleton }from '@material-ui/lab';
import { ReCaptcha,loadReCaptcha } from 'react-recaptcha-google'
import moment from "moment";
import api from '../services/api';
import { logout } from '../services/auth';
import Menu from './Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: "100%",
        paddingBottom: 5
    },
    rootComentarios: {
        flexGrow: 1,
        width: "100%",
        padding: 5,
        marginTop: 5,
        marginBottom: 5,
    },
    botaoVoto: {
        backgroundColor: "#3d6c7b",
        color: "white",
        "&:hover": {
            backgroundColor: "#3c788c",
        },
        textTransform: "none"
    },
    botaoVotos: {
        backgroundColor: "#66b4ce",
        color: "white",
        "&:hover": {
            backgroundColor: "#3c788c",
        },
        textTransform: "none"
    },
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        paddingTop: 10
    },
    container: {
        backgroundColor: "white"
    },
    linhaSeparacao: {
        marginTop: 15,
        marginBottom: 25,
        opacity: 0.4
    }
});
class Detailideia extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: true,
        data: {
            comentarios: [],
            comentariosPendentes: []
        },
        loggedUserData: JSON.parse(localStorage.getItem("userState")) || [],
        typeComment: "",
        alert: [],
        recaptchaToken: null,
        isEditing: false,
        EditingButtonText: "EDITAR IDEIA",
        tmpEditData: null,
        collapseComentarios: false
    };
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
  }
  onLoadRecaptcha() {
    if (this.captchaDemo) {
        this.captchaDemo.reset();
        this.setState({recaptchaToken: null});
    }
  }
  async componentDidMount() {
      this._isMonted = true;
      if(this.props.match.params && this._isMonted){
        let url = "?method=getIdea&id="+this.props.match.params.id;
        await api.get(url)
        .then(response => response.data)
        .then(data => {
            if(data.error === "Expired token") {
                logout();
                this.props.history.push("/login");
            } else if(data.status){
                this.setState({data: data.data, tmpEditData: data.data, loading: false})
            } else {
                this.props.history.goBack();
            }
        });
        loadReCaptcha();
      }
  }
  componentWillUnmount() { 
    this._isMonted = false;
  }
  handleVotoIdeia = async (event) => {
    if(!this.state.loggedUserData.IdUsuario){
        this.setState({alert: {open: true, message: "Você deve entrar no sistema para votar em alguma ideia", type: "warning"}})
        return;
    }
    var formData = new FormData();
    formData.append("IdUser",this.state.loggedUserData.IdUsuario);
    formData.append("IdIdeia",this.props.match.params.id);
    const response = await api.post("?method=VotarIdeia",formData);
    const data = await response.data;
    if(data.status) {
        this.setState({data: {...this.state.data, Votos: parseInt(this.state.data.Votos)+1},alert: {open: true, message: "Voto computado com sucesso, obrigado!", type: "success"}})
        return;
    } else {
        this.setState({alert: {open: true, message: data.error, type: "warning"}})
        return;
    }
  }
  handleSendComment = async (event) => {
    if(!this.state.loggedUserData.IdUsuario){
        this.setState({alert: {open: true, message: "Você deve entrar no sistema para enviar um comentário!", type: "warning"}})
        return;
    }
    if(this.state.typeComment == null || this.state.typeComment.length === 0){
        this.setState({alert: {open: true, message: "O texto do comentário não pode ficar em branco!", type: "error"}})
        return;
    }
    if(this.state.recaptchaToken === null){
        this.setState({alert: {open: true, message: "Clique no recaptcha primeiro e depois comente!", type: "warning"}})
        return;
    }
    var formData = new FormData();
    formData.append("IdUser",this.state.loggedUserData.IdUsuario);
    formData.append("IdIdeia",this.props.match.params.id);
    formData.append("textoComentario",this.state.typeComment);
    formData.append("recaptcha",this.state.recaptchaToken);
    const response = await api.post("?method=sendComment",formData);
    const data = response.data;
    if(data.status) {
        this.setState({data: {...this.state.data, comentariosPendentes: data.data.comentariosPendentes},typeComment: "",alert: {open: true, message: "Comentário postado com sucesso e está pendente de aprovação pela equipe Opus!", type: "success"}})
        this.onLoadRecaptcha();
    } else {
        this.setState({alert: {open: true, message: data.error, type: "warning"}})
    }
  }
  handleEditIdeia = async (event) => {
      if(this.state.EditingButtonText === "SALVAR EDIÇÃO"){
        if((this.state.data.Titulo !== this.state.tmpEditData.Titulo||
            this.state.data.Descricao !== this.state.tmpEditData.Descricao ||
            this.state.data.Status !== this.state.tmpEditData.Status ||
            this.state.data.Publicada !== this.state.tmpEditData.Publicada) && this.state.loggedUserData.isAdmin === "1") 
        {
            var formData = new FormData();
            formData.append("IdUser",this.state.loggedUserData.IdUsuario);
            formData.append("IdIdeia",this.props.match.params.id);
            formData.append("Titulo",this.state.tmpEditData.Titulo);
            formData.append("Descricao",this.state.tmpEditData.Descricao);
            formData.append("Status",this.state.tmpEditData.Status);
            formData.append("Publicada",this.state.tmpEditData.Publicada);
            const response = await api.post("?method=saveEditIdeia",formData);
            if(response.data.status){
                this.setState({data: this.state.tmpEditData,alert: {open: true, message: "Ideia foi editada com sucesso!", type: "success"}})
            } else {
                this.setState({alert: {open: true, message: "Não foi possível editar a ideia!", type: "error"}})
            }
        }
      }
      this.setState({isEditing: !this.state.isEditing, EditingButtonText: this.state.isEditing ? "EDITAR IDEIA" : "SALVAR EDIÇÃO"})
  }
  handleStatusComentario = async (dados, Status) => {   
    console.log(dados);
    if(this.state.loggedUserData.isAdmin === "1"){
        var formData = new FormData();
        formData.append("IdComentario",dados.IdComentario);
        formData.append("Status",Status);
        const response = await api.post("?method=editarComentario",formData);
        if(response.data.status){
            if(Status === "Aprovado"){
                this.setState({data: {...this.state.data, comentarios: [...this.state.data.comentarios, dados], comentariosPendentes: this.state.data.comentariosPendentes.filter((value) => value.IdComentario !== dados.IdComentario)},alert: {open: true, message: "O comentário foi aprovado com sucesso!", type: "success"}})
            } else {
                this.setState({data: {...this.state.data, comentariosPendentes: this.state.data.comentariosPendentes.filter((value) => value.IdComentario !== dados.IdComentario)},alert: {open: true, message: "O comentário foi deletado com sucesso!", type: "success"}})
            }
            
        } else {
            this.setState({alert: {open: true, message: "Não foi possível editar o status do comentário!", type: "error"}})
        }
      }
  }
  async componentDidUpdate() {
    if(this.state.alert.open){
        setTimeout(()=> {this.setState({alert: {open: false, message: "", type: "info"}})}, 5000)
    }
  }
  render() {
    const { classes } = this.props;
    return (
        <div style={{overflowY: "hidden"}}>
        <Menu />
        <Backdrop className={classes.backdrop} open={this.state.loading} style={{zIndex: 100000}}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Container className={classes.container} component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>
                <div style={{paddingLeft: 5}}>
                    <IconButton edge="start" className={classes.menuButton} style={{backgroundColor: "#f1f1f1"}} color="inherit" aria-label="menu" onClick={this.props.history.goBack}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div style={{padding: 40, paddingTop: 10}}>
                    <Grid container className={classes.root} spacing={0}>
                        <Grid item xs={10} style={{paddingLeft: 5}}>
                            <Typography variant="h5" style={{float: "left", width: "100%", marginBottom: this.state.isEditing ? 15 : 5}}>
                                {this.state.isEditing ? 
                                    <TextField
                                        variant="outlined"
                                        required
                                        id="name_ideia"
                                        label="Nome da ideia"
                                        name="name_ideia"
                                        type="text"
                                        autoFocus
                                        fullWidth
                                        value={this.state.tmpEditData.Titulo}
                                        onChange={(e) => this.setState({tmpEditData: {...this.state.tmpEditData, Titulo: e.target.value}})}
                                    />
                                :
                                    <strong>{this.state.data.Titulo}</strong>
                                } 
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{paddingLeft: 5}}>
                            {this.state.loggedUserData.isAdmin === "1" && 
                            <Button variant="contained" style={{float: "right"}} onClick={this.handleEditIdeia}>
                                {this.state.EditingButtonText}
                            </Button>
                            }
                        </Grid>
                        <Grid item xs={12} style={{paddingLeft: 5}}>
                            {this.state.loading ? 
                                <Skeleton variant="text" /> 
                            : 
                                <Typography variant="subtitle1">
                                    Criada em: <strong>{moment(this.state.data.DataCriacao).format("DD/MM/YYYY")}</strong> - {this.state.loading ? 
                                <Skeleton variant="text" /> 
                                : 
                                    this.state.isEditing ?
                                    <div style={{display: "inline-block"}}>
                                        <InputLabel htmlFor="filled-status">Status</InputLabel>
                                        <Select
                                            native
                                            value={this.state.tmpEditData.Status}
                                            onChange={(e) => this.setState({tmpEditData: {...this.state.tmpEditData, Status: e.target.value}})}
                                            inputProps={{
                                                name: 'status',
                                                id: 'filled-status',
                                            }}
                                            >
                                            <option value={"Nova"}>Nova</option>
                                            <option value={"Qualificada"}>Qualificada</option>
                                            <option value={"Em análise"}>Em análise</option>
                                            <option value={"Implementada"}>Implementada</option>
                                            <option value={"Descartada"}>Descartada</option>
                                        </Select>
                                    </div>
                                    :
                                    <span>Status: <strong>{this.state.data.Status}</strong></span>
                                }
                                    {this.state.loggedUserData.isAdmin === "1" ? 
                                        this.state.loading ? 
                                        <Skeleton variant="text" /> 
                                        : 
                                            this.state.isEditing ?
                                            <div style={{display: "inline-block"}}>
                                                &nbsp;-&nbsp;
                                                <InputLabel htmlFor="filled-status">Publicada</InputLabel>
                                                <Select
                                                    native
                                                    value={this.state.tmpEditData.Publicada}
                                                    onChange={(e) => this.setState({tmpEditData: {...this.state.tmpEditData, Publicada: e.target.value}})}
                                                    inputProps={{
                                                        name: 'publicada',
                                                        id: 'filled-publicada',
                                                    }}
                                                    >
                                                    <option value={"Sim"}>Sim</option>
                                                    <option value={"Não"}>Não</option>
                                                </Select>
                                            </div>
                                            :
                                            <span>&nbsp;-&nbsp;Publicada: <strong>{this.state.data.Publicada}</strong></span>
                                    : null}
                                    </Typography>
                            }
                        </Grid>
                    </Grid>
                    <hr className={classes.linhaSeparacao} />
                    <Grid container className={classes.root} spacing={0}>
                        <Grid item xs={10} style={{paddingLeft: 5}}>
                            {this.state.loading ? 
                                <Skeleton variant="rect" width={500} height={200} style={{padding: 10}}  /> 
                            :
                            <div>
                                <Typography variant="subtitle1">
                                    Descrição:
                                </Typography>
                                <Paper square style={{padding: 20,borderRadius: 0, boxShadow: "none"}}>
                                    {this.state.isEditing ?
                                        <TextField
                                            label="Descricao"
                                            multiline
                                            rows={5}
                                            variant="outlined"
                                            value={this.state.tmpEditData.Descricao}
                                            style={{width: "100%",minWidth: "100%",maxWidth: "100%"}}
                                            onChange={(e) => this.setState({tmpEditData: {...this.state.tmpEditData, Descricao: e.target.value}})}
                                        />
                                    :
                                        <Typography variant="subtitle1">
                                            {this.state.data.Descricao}
                                        </Typography>
                                    }
                                </Paper>
                                {this.state.loading ? 
                                    <Skeleton variant="circle" width={40} height={40}  /> 
                                : 
                                    <div style={{marginTop: 5}}>
                                    Criada por: {this.state.data.Usuario_Nome} - {this.state.data.Usuario_Empresa}</div>
                                }
                            </div>
                            }
                        </Grid>
                        <Grid item xs={2} sm={2} style={{justifyContent: "center"}}>
                            {this.state.loading ? 
                                <Skeleton variant="rect" width={80} height={80} style={{marginLeft: 10}} /> 
                            : 
                                <div style={{display: "inline-block",textAlign: "center", float:"right"}}>
                                <Button variant="contained" className={classes.botaoVotos}>
                                    {this.state.data.Votos}<br/>
                                    Votos
                                </Button> <br />
                                <Button variant="outlined" 
                                onClick={this.handleVotoIdeia}
                                className={classes.botaoVoto} style={{marginTop: 5}} color="primary">
                                    Votar
                                </Button></div>
                            }
                        </Grid>
                    </Grid>
                    <hr className={classes.linhaSeparacao} />
                    <Grid container className={classes.root} spacing={0} style={{marginTop: 5}}>
                        {this.state.loggedUserData.isAdmin === "1" &&
                        <Grid container className={classes.root} spacing={0} style={{marginTop: 5}}>
                            <Grid item xs={12} style={{cursor: "pointer"}} onClick={() => this.setState({collapseComentarios: !this.state.collapseComentarios})}> 
                                <Typography variant="h5">
                                    Comentários pendentes ({this.state.data.comentariosPendentes ? this.state.data.comentariosPendentes.length : '0'}) {this.state.collapseComentarios ? <ArrowDropDownIcon style={{fontSize: 35, position: "absolute"}}/> : <ArrowDropUpIcon style={{fontSize: 35, position: "absolute"}} />}
                                </Typography>
                            </Grid>
                            <Collapse in={this.state.collapseComentarios} timeout="auto" unmountOnExit style={{width: "100%"}}>
                                <Grid item xs={12} style={{marginTop: 30}}>
                                    {this.state.data.comentariosPendentes && this.state.data.comentariosPendentes.map((value, key) => {
                                        return (
                                            <Grid container className={classes.rootComentarios} key={key} spacing={0}>
                                                <Grid item xs={2}> 
                                                    <div>
                                                        <Avatar>
                                                            {value.Usuario_Nome.substring(0,1)}
                                                        </Avatar>
                                                        {value.Usuario_Nome}<br/>
                                                        {value.Usuario_Empresa}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={9}> 
                                                    <Paper square style={{padding: 20}}>
                                                        <Typography variant="subtitle1">
                                                            {value.Texto}
                                                        </Typography>
                                                    </Paper>
                                                    <Typography variant="subtitle2" style={{marginTop: 5,marginLeft: 3}}>
                                                        {moment(value.DataCriacao).format("DD/MM/YYYY")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1} style={{paddingTop: 20, cursor: "pointer"}}> 
                                                    <DoneIcon onClick={(e) => this.handleStatusComentario(value,"Aprovado")} style={{marginLeft: 5, marginRight: 5}} />
                                                    <DeleteIcon onClick={(e) => this.handleStatusComentario(value,"Deletar")}/>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Collapse>
                            <hr className={classes.linhaSeparacao} />
                        </Grid>
                        }
                        <Grid item xs={12}> 
                            <Typography variant="h5">
                                Comentários ({this.state.data.comentarios ? this.state.data.comentarios.length : '0'})
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: this.state.data.comentarios.length >= 1 ? 30 : 5}}>
                            {(this.state.data.comentarios || []).map((value, key) => {
                                return (
                                    <Grid container className={classes.rootComentarios} key={key} spacing={0}>
                                        <Grid item xs={2}> 
                                            <div>
                                                <Avatar>
                                                    {value.Usuario_Nome.substring(0,1)}
                                                </Avatar>
                                                {value.Usuario_Nome}<br/>
                                                {value.Usuario_Empresa}
                                            </div>
                                        </Grid>
                                        <Grid item xs={10}> 
                                            <Paper square style={{padding: 20}}>
                                                <Typography variant="subtitle1">
                                                    {value.Texto}
                                                </Typography>
                                            </Paper>
                                            <Typography variant="subtitle2" style={{marginTop: 5,marginLeft: 3}}>
                                                {moment(value.DataCriacao).format("DD/MM/YYYY")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <Grid item xs={12} style={{marginTop: this.state.data.comentarios.length >= 1 ? 30 : 5}}>
                            <Grid container className={classes.rootComentarios} spacing={0}>
                                <Grid item xs={12}> 
                                    <Paper square style={{padding: 20,paddingBottom: 50}}>
                                        <TextField
                                            label="Deixe seu comentário"
                                            multiline
                                            value={this.state.typeComment}
                                            rows={4}
                                            variant="outlined"
                                            aria-label="maximum height"
                                            placeholder="Deixe seu comentário"
                                            style={{width: "100%",minWidth: "100%",maxWidth: "100%"}}
                                            onChange={(event) => this.setState({typeComment: event.target.value})}
                                        />
                                        <ReCaptcha
                                            ref={(el) => {this.captchaDemo = el;}}
                                            size="normal"
                                            data-theme="dark"            
                                            render="explicit"
                                            sitekey="6LdB568ZAAAAAD5vkWts3ZPE6I-LG8xjHJSu_jyS"
                                            hl={"pt-br"}
                                            onloadCallback={this.onLoadRecaptcha}
                                            verifyCallback={(recaptchaToken) => this.setState({recaptchaToken})}
                                        />
                                        <Button variant="outlined"
                                            onClick={this.handleSendComment}
                                            style={{marginTop: 5,marginLeft: 3, float: "right"}} color="primary">
                                            Enviar comentário
                                        </Button>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            </Container>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.alert.open}
            ><Alert severity={this.state.alert.type}>{this.state.alert.message}</Alert></Snackbar>
        </div>
    );
  }
}
export default withRouter(withStyles(useStyles)(Detailideia));
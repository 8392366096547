import React, { Component } from 'react';
import logo from '../assets/imgs/header2.jpg';
import './Inicial.css';
import { Paper,Typography,Grid,Button,CircularProgress,Backdrop } from '@material-ui/core';
import {EmojiObjectsTwoTone,Code,SearchTwoTone }from '@material-ui/icons';
import { Link } from "react-router-dom";
import api from '../services/api';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import ModalRegistrarIdeia from "./ModalRegistrarIdeia";
import { logout } from '../services/auth';

import Menu from './Menu';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: "25px"
  },
  paper: {
    height: 200,
    width: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center", 
    cursor: "pointer",
    padding: 5,
    "&:hover": {
      boxShadow: "2px 2px 50px 0px rgba(0,0,0,0.2)",
    },
  },
  control: {
    padding: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const animateValue = (id, start, end, duration) => {
  if(end === 0) return;
  var range = end - start;
  var current = start;
  var increment = end > start? 1 : -1;
  var stepTime = Math.abs(Math.floor(duration / range));
  var obj = document.getElementById(id);
  var timer = setInterval(function() {
      current += increment;
      obj.innerHTML = current;
      if (current === end) {
          clearInterval(timer);
      }
  }, stepTime);
}

class Inicial extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      initInfo: [],
      loading: true,
      userData: JSON.parse(localStorage.getItem('userState')) || ''
    };
  }

  async componentDidMount () {
    this._isMounted = true;
    let url = "?method=paginaInicial";
    const response = await api.get(url);
    if(response.data.status){
      this._isMounted && this.setState({initInfo: response.data.data, loading: false});
    } else {
      if(response.data.error === "Expired token"){
        logout();
        this.props.history.push("/login");
      }
    }
  }
  componentWillUnmount () {
    this._isMounted = false;
  }
  componentDidUpdate() {
    if(this.state.initInfo.Ideias){
      animateValue("upIdeias",0,this.state.initInfo.Ideias, 1000);
      animateValue("upImplementadas",0,this.state.initInfo.Analisadas, 1000);
      animateValue("upQualificadas",0,this.state.initInfo.Implementadas, 1000);
    }
  }
  render() {
    const { classes } = this.props;
    return (
        <div style={{overflowY: "hidden"}}>
          <Menu />
          <header className="App-header" style={{overflow: "hidden"}}>
              <img src={logo} className="logo" alt="logo" />
              <div>
                <Typography style={{marginTop: "25px"}}  align="center" variant="h6" component="h5">
                    Possui ideias? Registre agora ou análise outras ideias já registradas.
                </Typography>
                <ModalRegistrarIdeia />
                <Typography style={{marginTop: "5px"}}  align="center" variant="h6" component="h6">
                    ou
                </Typography>
                <Link to="/ideias" style={{textDecoration: "none",color: "white"}}>
                    <Button color="inherit">Ver todas as ideias</Button>
                </Link>
              </div>
              <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                  <Grid container justify="center" spacing={10}>
                      <Grid key={0} item>
                        <Paper className={classes.paper} onClick={() => this.props.history.push("/ideias")}>
                          <EmojiObjectsTwoTone style={{fontSize: "60px"}} />
                          <Typography style={{marginTop: "15px"}} align="center" variant="h6" component="h6">
                            <div id="upIdeias">{this.state.initInfo.Ideias}</div> Ideias registradas
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid key={1} item>
                        <Paper className={classes.paper} onClick={() => this.props.history.push("/ideias?Analisadas")}>
                          <Code style={{fontSize: "60px"}} />
                          <Typography style={{marginTop: "15px"}} align="center" variant="h6" component="h6">
                            <div id="upImplementadas">{this.state.initInfo.Analisadas}</div> Analisadas
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid key={2} item>
                      <Paper className={classes.paper} onClick={() => this.props.history.push("/ideias?implementadas")}>
                          <SearchTwoTone style={{fontSize: "60px"}} />
                          <Typography style={{marginTop: "15px"}} align="center" variant="h6" component="h6">
                            <div id="upQualificadas">{this.state.initInfo.Implementadas}</div> Implementadas
                          </Typography>
                        </Paper>
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Backdrop className={classes.backdrop} open={this.state.loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
          </header>
        </div>
    );
  }
};
export default withRouter(withStyles(useStyles)(Inicial));

import React from 'react';
import { Backdrop,CssBaseline,TextField,Link,Grid,Box,Typography,Container,Button,Snackbar,Avatar,CircularProgress  } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import api from '../services/api';
import { login } from '../services/auth';
import Menu from './Menu';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://opus127.com.br/">
        Opus127
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const SignIn = (props) => {
  const classes = useStyles();
  const [userState] = React.useState(JSON.parse(localStorage.getItem('userState')) || '');
  if(userState){
    props.history.push("/");
  }
  const [dataEmail, setDataEmail] = React.useState('');
  const [dataSenha, setDataSenha] = React.useState('');
  const [dataAlert, setDataAlert] = React.useState('');
  const [loading, setLoading] = React.useState(false); 
  
  const handleSubmited = async (event) => {
    event.preventDefault();
    setLoading(true);
    var formData = new FormData();
    formData.append("dataEmail",dataEmail);
    formData.append("dataSenha",dataSenha);
    const response = await api.post("?method=tryLogin", formData);
    const data = await response.data;
    var dados = {
        message: null,
        status: true
    };
    if(data.status){
      localStorage.setItem("userState", JSON.stringify(data.data));
      dados.message = "Você entrou com sucesso, bem vindo!";
      login(JSON.parse(data.data.token)["jwt"]);
      setTimeout(()=> {props.history.push("/")},500);
    } else {
      dados.message = data.error;
    }
    setDataAlert(dados);
    setLoading(false);
  }
  React.useEffect(() => {
    if(dataAlert.status){
        setTimeout(()=> {setDataAlert({message: null,status: false})}, 5000)
    }
  });
  return (
    <div style={{overflowY: "hidden"}}>
      <Menu />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form className={classes.form} validate="true">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setDataEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setDataSenha(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmited}
            >
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Esqueceu a senha?
                </Link>
              </Grid>
              <Grid item>
                <Link href="mailto:suporte@opus127.com.br" variant="body2">
                  {"Não possui uma conta? Cadastre-se agora"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
        <Snackbar
          open={dataAlert.status}
          anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
          message={dataAlert.message}
          key={"bottom+center"}
        />
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
      </div>
  );
}
export default withRouter(SignIn);
import React, { Component } from 'react';
import { Typography,Grid,Button,Avatar,Tooltip  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    botaoVoto: {
        backgroundColor: "#66b4ce",
        color: "white",
        "&:hover": {
            backgroundColor: "#3c788c",
        },
    }
});
class IdeiaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  render() {
    const { classes } = this.props;
    return (
        <Grid container className={classes.root} spacing={0}>
            {this.props.isAdmin ?
            <Grid item xs={1} md={1}> 
                {this.props.Publicada === "Sim" ?
                <Tooltip title="Essa ideia já foi publicada">
                    <Avatar style={{width: 20,height: 20,marginTop: 20,marginLeft: 10,backgroundColor: "green"}}>
                        &nbsp;
                    </Avatar>
                </Tooltip>
                :
                <Tooltip title="Essa ideia não foi publicada ainda">
                    <Avatar style={{width: 20,height: 20,marginTop: 20,marginLeft: 10,backgroundColor: "red"}}>
                        &nbsp;
                    </Avatar>
                </Tooltip>
                }
            </Grid>
            :
            <Grid item xs={1} md={1}> 
                <Typography variant="subtitle1">
                    #{this.props.IdIdeia}
                </Typography>
            </Grid>
            }
            <Grid item xs={9} md={10}>
                <Typography variant="subtitle1">
                    {this.props.Titulo}
                </Typography>
                <Typography variant="body2">
                    {this.props.Descricao}
                </Typography>
            </Grid>
            <Grid item xs={1} md={1}>
                <Button variant="contained" className={classes.botaoVoto}>
                    {this.props.Votos}<br/>
                    Votos
                </Button>
            </Grid>
        </Grid>
    );
  }
}
export default withStyles(useStyles)(IdeiaComponent);
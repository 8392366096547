import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import NotFound from './components/NotFound';
import Login from './components/SignIn';
import Sair from './components/Logout';
import Ideias from './components/Ideias'
import Detailideia from './components/Detailideia';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route,Switch, Redirect } from 'react-router-dom';
import { isAuthenticated,isAdmin } from "./services/auth";
import Cadastro from './components/SingUp';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);
const PrivateRouteAdmin = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      console.log(isAdmin());
      return isAdmin() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
    }
  />
);


ReactDOM.render(
  <Router>
    <Switch>
      <PrivateRoute exact path='/' component={App} />
      <Route exact path='/login' component={Login} />
      <PrivateRoute exact path='/ideias' component={Ideias} />
      <PrivateRoute exact path='/logout' component={Sair} />
      <PrivateRoute exact path='/ideia/:id' component={Detailideia} />
      <PrivateRouteAdmin exact path="/cadastrarUsuario" component={Cadastro}/>
      <Route component={NotFound}/>
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer,Button,MenuItem,Container,CssBaseline,Avatar,Typography,TextField,Snackbar,Backdrop,CircularProgress } from '@material-ui/core';
import EmojiObjectsTwoTone from '@material-ui/icons/EmojiObjectsTwoTone';
import { Alert }from '@material-ui/lab';
import { ReCaptcha,loadReCaptcha } from 'react-recaptcha-google'
import api from '../services/api';
import './ModalRegistrarIdeia.css';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 400,
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function ModalRegistrarIdeia() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [recaptcha, setRecaptcha] = React.useState(null);
  const [state, setState] = React.useState({
    right: false,
    alert: {
        type: "info",
        message: null,
        open: false
    },
    formData: {
        titulo: null,
        descricao: null,
        problema: null, 
        aceitapagar: "Não"
    },
    userState: JSON.parse(localStorage.getItem("userState")) || null,
    loading: false,
    recaptcha: null
  });
  React.useEffect(() => {
    if(state.alert.open){
        setTimeout(()=> {setState({...state, alert: {open: false, message: "", type: "info"}})}, 5000)
    }
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
    loadReCaptcha();
  };
  const handleSendIdeia = async (event) => {
    event.preventDefault();
    setLoading(true);
    if(state.formData.titulo === null){
      setState({...state, alert: {type: "error",message: "O Título não pode ser em branco!",open: true}})
      setLoading(false);
      return;
    }
    if(state.formData.descricao === null){
      setState({...state, alert: {type: "error",message: "A Descrição não pode ser em branco!",open: true}})
      setLoading(false);
      return;
    }
    if(state.formData.problema === null){
      setState({...state, alert: {type: "error",message: "O Problema não pode ser em branco!",open: true}})
      setLoading(false);
      return;
    }
    if(state.formData.aceitapagar === null){
      setState({...state, alert: {type: "error",message: "A seleção de AceitaPagar não pode ser em branco!",open: true}})
      setLoading(false);
      return;
    }
    var formData = new FormData();
    formData.append("Titulo",state.formData.titulo);
    formData.append("Descricao",state.formData.descricao);
    formData.append("Problema",state.formData.problema);
    formData.append("AceitaPagar",state.formData.aceitapagar);
    formData.append("IdUser",state.userState.IdUsuario);
    formData.append("recaptcha",recaptcha);
    const response = await api.post("?method=tryRegIdeia",formData)
    const data = response.data;
    if(data.status){
      setState({...state, alert: {type: "success",message: "A sua ideia foi registrada com sucesso e já vai ser analisada pela equipe da Opus e assim publicada, obrigado!",open: true}});
      setTimeout(() => {
        document.location.href="/ideias";
      }, 5000);
    } else {
      setState({...state, alert: {type: "error",message: "Não foi possível registrar sua ideia, tente novamente!",open: true}})
    }
    setLoading(false);
  }
  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <CloseIcon style={{right: 0,position: "absolute",marginRight: 20,cursor: "pointer"}} onClick={toggleDrawer("right", false)} />
            <Avatar className={classes.avatar}>
              <EmojiObjectsTwoTone />
            </Avatar>
            <Typography component="h1" variant="h5">
              Ideia
            </Typography>
            <form className={classes.form} validate="true">
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Título"
                name="titulo"
                type="text"
                autoFocus
                onChange={(e) => setState({ ...state, formData: {...state.formData, titulo: e.target.value }})}
            />
            <TextField
                variant="outlined"
                rows={4}
                multiline
                margin="normal"
                label="Descrição da ideia"
                aria-label="maximum height"
                placeholder="Descrição da ideia"
                style={{width: "100%",minWidth: "100%",maxWidth: "100%"}}
                onChange={(e) => setState({ ...state, formData: {...state.formData, descricao: e.target.value }})}
            />
            <TextField
                variant="outlined"
                rows={4}
                multiline
                margin="normal"
                label="Qual é o problema que essa ideia resolve?"
                aria-label="maximum height"
                placeholder="Qual é o problema que essa ideia resolve?"
                style={{width: "100%",minWidth: "100%",maxWidth: "100%"}}
                onChange={(e) => setState({ ...state, formData: {...state.formData, problema: e.target.value }})}
            />
            <TextField
                id="standard-select-currency"
                select
                label="Aceita pagar?"
                margin="normal"
                value={state.formData.aceitapagar}
                style={{width: "100%",minWidth: "100%",maxWidth: "100%", marginTop: 7}}
                onChange={(e) => setState({ ...state, formData: {...state.formData, aceitapagar: e.target.value }})}
                helperText="Você aceita pagar pela modificação no sistema?"
                >
                <MenuItem key={"Sim"} value={"Sim"}>
                    Sim
                </MenuItem>
                <MenuItem key={"Não"} value={"Não"}>
                    Não
                </MenuItem>
            </TextField>
            <ReCaptcha
              style={{marginTop: 5}}
              sitekey="6LdB568ZAAAAAD5vkWts3ZPE6I-LG8xjHJSu_jyS"
              hl={"pt-br"}
              size="responsive"
              render="explicit"
              onloadCallback={() => console.log("recaptcha carregado")}
              verifyCallback={(data) => setRecaptcha(data)}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSendIdeia}
            >
                Enviar ideia
            </Button>
            </form>
        </div>
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        </Container>
    </div>

  );

  return (
    <div>
        <React.Fragment key={"right"}>
            <Button variant="contained" style={{width: "auto",fontSize: "13px"}} onClick={state.userState ? toggleDrawer("right", true) : () => !state.alert.open && setState({...state, alert: {type: "error",message: "Você deve estar logado no sistema para registrar uma ideia!",open: true}})} className="corBotao">
              Registre sua ideia
            </Button>
            <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)}>
              {list("right")}
            </Drawer>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={state.alert.open}
            ><Alert severity={state.alert.type}>{state.alert.message}</Alert></Snackbar>
        </React.Fragment>
    </div> 
  );
}
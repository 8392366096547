import { withRouter } from 'react-router-dom';
import { logout } from '../services/auth';

const Logout = (props) => {
    logout();
    document.location.href = "/";
    return (
        null
    );
}
export default withRouter(Logout);
